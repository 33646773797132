body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url("./bg.jpg");
  background-size: cover;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.logo {
  max-width: 400px;
}

footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #46464680;
  color: #fff;
  padding: 16px;
}

footer div {
  text-decoration: none;
  color: #fff;
  margin-right: 16px;
  margin-bottom: 16px;
}

footer ul {
  list-style: none;
}

footer a {
  text-decoration: none;
  color: #fff;
}

footer a:hover {
  text-decoration: underline;
}